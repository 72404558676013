import { ColumnConfig } from '@common/datatable/column-config';
import { Trans } from '@common/i18n/trans';
import { Avatar } from '@common/ui/images/avatar';
import { getProductImageUrl } from './product-link';
import { NameWithAvatar } from '@common/datatable/column-templates/name-with-avatar';
import { Link } from 'react-router-dom';

interface FieldConfig {
  field: string;
  title: string;
}

export function getProductDatatableColumns(config: FieldConfig[]): ColumnConfig<any>[] {
  return config.map((field: any) => ({
    key: field.field,
    allowsSorting: true,
    header: () => <Trans message={field.title} />,
    body: (product) =>
      field.colType === 'imagewithname' ? (
        <NameWithAvatar
          image={getProductImageUrl(product)}
          label={
            <Link
              to={getProductImageUrl(product)}
              target="_blank"
              className="hover:underline"
            >
              {product.name}
            </Link>
          }
        />
      ) : field.colType === 'imageonly' ? (
        <Avatar
          label={product[field.field]}
          src={getProductImageUrl(product)}
          link={getProductImageUrl(product)}
        />
      ) : (
        product[field.field]
      ),
  }));
}