import { Dialog } from '@common/ui/overlays/dialog/dialog';
import { DialogHeader } from '@common/ui/overlays/dialog/dialog-header';
import { Trans } from '@common/i18n/trans';
import { DialogBody } from '@common/ui/overlays/dialog/dialog-body';
import { useDialogContext } from '@common/ui/overlays/dialog/dialog-context';
import { useForm } from 'react-hook-form';
import { Form } from '@common/ui/forms/form';
import { DialogFooter } from '@common/ui/overlays/dialog/dialog-footer';
import { Button } from '@common/ui/buttons/button';
import { useAuth } from '@common/auth/use-auth';
import { CrupdateProductFields } from '@app/web-admin/products/crupdate-product-fields';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCreateProduct } from './use-create-product';

export function CreateProductDialog(props: any) {
  const { formId, close } = useDialogContext();
  const { user } = useAuth();
  const { projectId } = useParams<{ projectId: string }>();


  const form = useForm<any>({
    defaultValues: {
      projectId: projectId,
    },
  });

  const createProduct = useCreateProduct(form);

  return (
    <Dialog>
      <DialogHeader>
        <Trans message="New Product" />
      </DialogHeader>
      <DialogBody>
        <Form
          id={formId}
          form={form}
          onSubmit={(values) => {
            createProduct.mutate(
              { ...values, projectId }, // Pass form values and projectId
              {
                onSuccess: () => {
                  close(); // Close the dialog on success
                },
              }
            );
          }}
        >
          <CrupdateProductFields config={props} />
        </Form>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => close()}>
          <Trans message="Cancel" />
        </Button>
        <Button form={formId} type="submit" variant="flat" color="primary">
          <Trans message="Create" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}