import { Fragment } from 'react';
import { FormTextField } from '@common/ui/forms/input-field/text-field/text-field';
import { FormFileField } from '@common/ui/forms/input-field/file-field';
import { useFormContext } from 'react-hook-form';
import { Trans } from '@common/i18n/trans';

interface FieldConfig {
  field: string;
  title: string;
  type: string;
  validation: string;
}

interface Props {
  config: FieldConfig[];
}

export function CrupdateProductFields(props:any) {
  const form = useFormContext();
  const { config } = props;
  return (
    <Fragment>
      {config?.config.map((field:any) => {
        if (field.type === 'string' || field.type === 'number') {
          return (
            <FormTextField
              key={field.field}
              name={field.field}
              label={<Trans message={field.title} />}
              className="mb-14"
              required={field.validation === 'required'}
              type={field.type === 'number' ? 'number' : 'text'}
            />
          );
        } else if (field.type === 'file') {
          return (
            <FormFileField
              key={field.field}
              name={field.field}
              label={<Trans message={field.title} />}
              className="mb-20"
              required={field.validation === 'required'}
              accept="image/*"
              onChange={() => form.clearErrors()}
            />
          );
        }
        return null;
      })}
    </Fragment>
  );
}