import {useMutation, useQueryClient} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {apiClient, queryClient} from '@common/http/query-client';
import {toast} from '@common/ui/toast/toast';
import {useTrans} from '@common/i18n/use-trans';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {message} from '@common/i18n/message';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {EditorProject} from '@app/dashboard/project';
import {getProductionHtml} from '@app/editor/utils/parse-html-into-document';
import {BLANK_PAGE_SKELETON} from '@app/projects/blank-page-skeleton';
import {BuilderTemplate} from '@app/templates/builder-template';
import {fetchTemplate} from '@app/templates/use-template';
import {reloadAccountUsage} from '@app/editor/use-account-usage';

interface Response extends BackendResponse {
  project: EditorProject;
}

export interface CreateProductPayload {
  projectId: string;
  name: string;
  description?: string;
  amount?: string;
  target_amount?: string;
  image?: File;
}

export function useCreateProduct(form: UseFormReturn<CreateProductPayload>) {
  const {trans} = useTrans();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: CreateProductPayload) => createProject(payload),
    onSuccess: async () => {
      reloadAccountUsage();
      await queryClient.invalidateQueries({queryKey: ['products']});
      toast(trans(message('product created')));
    },
    onError: err => onFormQueryError(err, form),
  });
}

async function createProject({
  projectId,
  name,
  description,
  amount,
  target_amount,
  image,
}: CreateProductPayload) {
  const formData = new FormData();
  formData.set('name', name);
  if (description) {
    formData.set('description', description);
  }
  if (amount) {
    formData.set('amount', amount);
  }
  if (target_amount) {
    formData.set('target_amount', target_amount);
  }
  if (image) {
    formData.set('image', image);
  }

  return apiClient
    .post<Response>(`products/${projectId}/store`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(r => r.data);
}
