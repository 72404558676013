import { Trans } from '@common/i18n/trans';
import React, { useEffect, useState } from 'react';
import { DataTablePage } from '@common/datatable/page/data-table-page';
import { DataTableAddItemButton } from '@common/datatable/data-table-add-item-button';
import { DeleteSelectedItemsAction } from '@common/datatable/page/delete-selected-items-action';
import { DataTableEmptyStateMessage } from '@common/datatable/page/data-table-emty-state-message';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { getProductDatatableColumns } from '@app/web-admin/products/product-datatable-columns';
import builderImage from '@app/templates/website-builder.svg';
import { CreateProductDialog } from '@app/web-admin/products/create-product-dialog';
import { Link, useParams } from 'react-router-dom';

export function CustomWebAdminPage() {
  const { projectId } = useParams<{ projectId: string }>();
  const [isActive, setIsActive] = React.useState(true);
  const [productData, setProductData] = useState<any>({});

  useEffect(() => {
    // Fetch config from API
    fetch(`api/v1/products/${projectId}?perPage=15&paginate=preferLengthAware`)
      .then((res) => res.json())
      .then((data: any) => setProductData(data));
  }, []);

  if (!productData.config) {
    return <div>Loading... No config added</div>;
  }
  console.log(productData);
  return (
    <>
      <nav className="flex absolute flex-shrink-0 flex-col items-center gap-0 border-r pt-8 w-[80px] h-full">

        <Link
          to="/dashboard"
          className="focus-visible:ring bg-transparent border-transparent hover:bg-hover disabled:text-disabled disabled:bg-transparent whitespace-nowrap flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-none justify-center text-base p-[15px] mb-18 text-muted"
          aria-label="Dashboard"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            data-testid="KeyboardArrowLeftOutlinedIcon"
            className="svg-icon icon-md"
            height="1em"
            width="1em"
          >
            <path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
          </svg>
        </Link>

        <Link
          to="/xwebbuilder/dashboard"
          className={`w-full focus-visible:ring hover:bg-hover disabled:text-disabled disabled:bg-transparent whitespace-nowrap flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-none justify-center text-base p-[15px] text-muted flex-col ${isActive ? 'bg-hover border-r-1 active-admin-menu' : ''
            }`}
          onClick={() => setIsActive(!isActive)}
          aria-label="Dashboard"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            data-testid="ShoppingCartOutlinedIcon"
            className="svg-icon icon-md"
            height="1em"
            width="1em"
          >
            <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zM7.82 14h8.36l1.1-5H6.72l1.1 5zM18.92 6H5.08L4.21 2H1v2h2l3.6 9.59-1.35 2.44C5.11 16.37 5 16.68 5 17c0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12L8.1 14h9.45c.75 0 1.41-.52 1.58-1.25l1.38-6.25c.05-.23.07-.47.07-.7 0-1.1-.9-2-2-2z"></path>
          </svg>
          <span className="text-[11px] mt-1">Products</span>
        </Link>

        <Link
          to="/xwebbuilder/dashboard"
          className="w-full focus-visible:ring bg-transparent border-transparent hover:bg-hover disabled:text-disabled disabled:bg-transparent whitespace-nowrap flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-none justify-center text-base p-[15px] text-muted flex-col"
          aria-label="Dashboard"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            data-testid="ReceiptOutlinedIcon"
            className="svg-icon icon-md"
            height="1em"
            width="1em"
          >
            <path d="M21 2H7c-1.1 0-2 .9-2 2v16c0 .55.45 1 1 1 .23 0 .46-.08.65-.22L8 20.5l1.35 1.28c.38.36.96.36 1.35 0L12 20.5l1.35 1.28c.38.36.96.36 1.35 0L16 20.5l1.35 1.28c.38.36.96.36 1.35 0L20 20.5l1.35 1.28c.19.14.42.22.65.22.55 0 1-.45 1-1V4c0-1.1-.9-2-2-2zm0 18h-2.35l-1.35-1.28c-.38-.36-.96-.36-1.35 0L14 20.5l-1.35-1.28c-.38-.36-.96-.36-1.35 0L10 20.5l-1.35-1.28c-.38-.36-.96-.36-1.35 0L6.35 20H5V4h16v16zM8 9h8v2H8zm0 4h8v2H8zm0-8h8v2H8z"></path>
          </svg>
          <span className="text-[11px] mt-1">Orders</span>
        </Link>
      </nav>
      <div className="container mx-auto flex-auto px-12">
        <DataTablePage
          endpoint={`products/${projectId}`}
          title={<Trans message={productData?.config.pageTitle} />}
          columns={getProductDatatableColumns(productData?.config.fields)}
          actions={<Actions config={productData?.config} />}
          selectedActions={<DeleteSelectedItemsAction />}
          emptyStateMessage={
            <DataTableEmptyStateMessage
              image={builderImage}
              title={<Trans message={productData?.config.emptyDataMessage} />}
              filteringTitle={<Trans message={productData?.config.dataNotFoundMessage} />}
            />
          }
        />
      </div>
    </>
  );
}

function Actions(props: any) {
  const { config } = props;
  return (
    <>
      <DialogTrigger type="modal">
        <DataTableAddItemButton>
          <Trans message={config?.addButton.title} />
        </DataTableAddItemButton>
        <CreateProductDialog config={config.fields} />
      </DialogTrigger>
    </>

  );
}